



@media print {
    @page {
        size: A4 landscape;
        margin: 0;
        /*margin-top: 10mm;*/
        margin-bottom: 10mm;
        margin-left: 5mm;
        margin-right: 5mm;
    }
	img{
		height :30px !important; 
        width:30px !important;
	}
	
}

